import { Button, ButtonStyle, Table } from '@geoinnova/geoinnova-core';
import { useState } from 'react';

const dummyDocuments: any = [
  {
    name: 'Aliquam.pptx',
    type: 'pptx',
    author: 'swhyman0@virginia.edu',
    createdDate: '4/10/2023',
  },
  {
    name: 'Ac.xlsx',
    type: 'xlsx',
    author: 'gbucknill1@ted.com',
    createdDate: '10/24/2023',
  },
  {
    name: 'IpsumDolor.pdf',
    type: 'pdf',
    author: 'dsirey2@1688.com',
    createdDate: '4/29/2023',
  },
  {
    name: 'PedeMalesuada.avi',
    type: 'avi',
    author: 'rbeake3@marriott.com',
    createdDate: '11/19/2023',
  },
  {
    name: 'Cubilia.mp3',
    type: 'mp3',
    author: 'rkrout4@google.ru',
    createdDate: '1/5/2023',
  },
  {
    name: 'UltricesEnimLorem.txt',
    type: 'txt',
    author: 'cmeryett5@rakuten.co.jp',
    createdDate: '12/4/2023',
  },
  {
    name: 'AuctorGravida.mpeg',
    type: 'mpeg',
    author: 'bmelsome6@mediafire.com',
    createdDate: '11/27/2023',
  },
  {
    name: 'In.mp4',
    type: 'mp4',
    author: 'ntowsie7@wiley.com',
    createdDate: '8/24/2023',
  },
  {
    name: 'SapienDignissimVestibulum.pdf',
    type: 'pdf',
    author: 'panglish8@cnbc.com',
    createdDate: '11/5/2023',
  },
  {
    name: 'SedTristique.mp3',
    type: 'mp3',
    author: 'ehuncoot9@youku.com',
    createdDate: '10/3/2023',
  },
  {
    name: 'IpsumPrimisIn.ppt',
    type: 'ppt',
    author: 'khabershawa@bbb.org',
    createdDate: '12/4/2023',
  },
  {
    name: 'ParturientMontesNascetur.ppt',
    type: 'ppt',
    author: 'hhinzeb@cmu.edu',
    createdDate: '11/19/2023',
  },
  {
    name: 'PlateaDictumstEtiam.mp4',
    type: 'mp4',
    author: 'obullockc@digg.com',
    createdDate: '11/28/2023',
  },
  {
    name: 'FaucibusOrciLuctus.csv',
    type: 'csv',
    author: 'dclawsleyd@statcounter.com',
    createdDate: '4/26/2023',
  },
  {
    name: 'Lectus.mp3',
    type: 'mp3',
    author: 'eblodgettse@microsoft.com',
    createdDate: '3/10/2023',
  },
  {
    name: 'Orci.pdf',
    type: 'pdf',
    author: 'bhastonf@weather.com',
    createdDate: '2/22/2023',
  },
  {
    name: 'Lacus.mp3',
    type: 'mp3',
    author: 'etrayhorng@jugem.jp',
    createdDate: '10/24/2023',
  },
  {
    name: 'Leo.ppt',
    type: 'ppt',
    author: 'elubertoh@admin.ch',
    createdDate: '8/13/2023',
  },
  {
    name: 'Morbi.xls',
    type: 'xls',
    author: 'apenai@fc2.com',
    createdDate: '4/9/2023',
  },
  {
    name: 'ConsectetuerEgetRutrum.csv',
    type: 'csv',
    author: 'cdevoj@amazon.co.uk',
    createdDate: '2/17/2023',
  },
  {
    name: 'Nisl.xls',
    type: 'xls',
    author: 'syakuntzovk@joomla.org',
    createdDate: '10/30/2023',
  },
  {
    name: 'EnimIn.doc',
    type: 'doc',
    author: 'fchargel@acquirethisname.com',
    createdDate: '2/13/2023',
  },
  {
    name: 'Libero.xlsx',
    type: 'xlsx',
    author: 'strembletm@state.tx.us',
    createdDate: '7/3/2023',
  },
  {
    name: 'AmetSapienDignissim.pdf',
    type: 'pdf',
    author: 'jmcquorkeln@constantcontact.com',
    createdDate: '5/4/2023',
  },
  {
    name: 'EtiamJustoEtiam.pdf',
    type: 'pdf',
    author: 'cdanihelkao@nyu.edu',
    createdDate: '9/8/2023',
  },
  {
    name: 'Erat.ppt',
    type: 'ppt',
    author: 'bdewettp@apple.com',
    createdDate: '1/1/2023',
  },
  {
    name: 'LacusPurus.png',
    type: 'png',
    author: 'mmeadq@mashable.com',
    createdDate: '3/5/2023',
  },
  {
    name: 'CondimentumNequeSapien.csv',
    type: 'csv',
    author: 'dflawnr@google.nl',
    createdDate: '10/18/2023',
  },
  {
    name: 'PedeMalesuada.pptx',
    type: 'pptx',
    author: 'jtomasicchios@marriott.com',
    createdDate: '9/12/2023',
  },
  {
    name: 'MassaQuisAugue.pdf',
    type: 'pdf',
    author: 'nbighamt@vimeo.com',
    createdDate: '4/23/2023',
  },
  {
    name: 'FaucibusOrciLuctus.xlsx',
    type: 'xlsx',
    author: 'fbambu@quantcast.com',
    createdDate: '5/3/2023',
  },
  {
    name: 'UltricesEnimLorem.mpeg',
    type: 'mpeg',
    author: 'abynev@typepad.com',
    createdDate: '6/27/2023',
  },
];

const dummyHeaders = [
  {
    prop: 'name',
    title: 'Nombre',
  },
  {
    prop: 'type',
    title: 'Tipo',
  },
  {
    prop: 'author',
    title: 'Autor',
  },
  {
    prop: 'createdDate',
    title: 'Fecha de Creación',
  },
];

export const DocumentManager = () => {
  const [documents, setDocuments] = useState(dummyDocuments);
  const [inputSearch, setInputSearch] = useState<string>('');

  const searchDocumentHandler = () => {
    if (inputSearch.length === 0) {
      setDocuments(dummyDocuments);
    } else {
      const filteredDocuments: any = dummyDocuments.filter((document: any) =>
        Object.values(document).includes(inputSearch),
      );
      setDocuments(filteredDocuments);
    }
  };
  const searchStringHanlder = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };
  return (
    <div>
      <input
        type="text"
        id="lname"
        name="lname"
        value={inputSearch}
        onChange={searchStringHanlder}
      />
      <Button
        label="Buscar"
        style={ButtonStyle.primary}
        onClick={searchDocumentHandler}
      />
      {documents && (
        <Table
          body={documents}
          headers={dummyHeaders}
          paginationRange={3}
          rowsPerPage={10}
          rowsPerPageOptions={[2, 4, 6, 8, 10]}
        />
      )}
    </div>
  );
};
