import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { DocumentManager } from './pages/DocumentManager';
import { PerformanceIndicator } from './pages/PerformanceIndicator';
import { Header, HeaderBgColor } from '@geoinnova/geoinnova-core';
import logoHeader from './assets/logo-coflow.svg';
interface LogoHeaderProps {
  image: React.ReactNode;
}

const LogoHeaderComponent: React.FC<LogoHeaderProps> = ({ image }) => {
  return <div>{image}</div>;
};
function App() {
  return (
    <BrowserRouter>
      <div>
        <Header
          bgColor={HeaderBgColor.bgWhite}
          logo={
            <LogoHeaderComponent
              image={
                <img
                  src={logoHeader}
                  style={{ width: '120px' }}
                  alt="Descripción del SVG"
                />
              }
            />
          }
          logoOnClick={() => {}}
          otherApps
          withRightIcons
          iconsArray={[]}
        />
        <div className='row p-5'>
          <div className='col-3'>
            <nav>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/perfomance-indicator">KPI</Link>
                </li>
                <li>
                  <Link to="/document-manager">Storage</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className='col-9'>
            {' '}
            <Routes>
              <Route caseSensitive element={<DocumentManager />} path="/" />
              <Route
                caseSensitive
                element={<DocumentManager />}
                path="/document-manager"
              />
              <Route
                caseSensitive
                element={<PerformanceIndicator />}
                path="/perfomance-indicator"
              />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
