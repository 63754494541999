import {
  Text,
  TextStyle,
  Button,
  ButtonStyle,
} from '@geoinnova/geoinnova-core';
import { Fragment, useState } from 'react';
export const PerformanceIndicator = () => {
  const [projectVisibility, setProjectVisibility] = useState(false);
  const [proposalVisibility, setProposalVisibility] = useState(false);

  const showProposal = () => {
    setProposalVisibility(true);
    setProjectVisibility(false);
  };

  const showProject = () => {
    setProposalVisibility(false);
    setProjectVisibility(true);
  };

  return (
    <div>
      <Button
        label="Propuestas"
        style={ButtonStyle.primary}
        onClick={showProposal}
      />
      <Button
        label="Proyectos"
        style={ButtonStyle.primary}
        onClick={showProject}
      />
      {proposalVisibility && (
        <Fragment>
          <Text content="Propuestas" style={TextStyle.h2} />
          <iframe
            title="propuestas"
            width="800"
            height="600"
            src="https://lookerstudio.google.com/embed/reporting/ea8cd0fb-23ef-4a0f-adc6-abe9b49366b9/page/Q3tjD"
            style={{ border: 0 }}
            allowFullScreen
          />
        </Fragment>
      )}
      {projectVisibility && (
        <Fragment>
          <Text content="Proyectos" style={TextStyle.h2} />
          <iframe
            title="proyectos"
            width="800"
            height="600"
            src="https://lookerstudio.google.com/embed/reporting/cf0e0324-446c-47f1-a4f5-36b79fe447f9/page/KKQlD"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </Fragment>
      )}
    </div>
  );
};
